'use strict';
// $ = jQuery;

/**
 * If 'menu' has class 'is-fixed'
 * Then we set 'scrollPos' with margin-top on 'sidebar'
 * In this case we don't need a position fixed.
 */

var sidebarFixed = function() {

	var screenWidth	= $(window).width();
	var header 			= $('.header').outerHeight();
	var headerBar		= $('.header__top-bar').outerHeight();
	var menu 			= $('.header__top-navigation');
	var sidebar	= $('.sidebar-navigation');
	var placeholder = $('.sidebar-placeholder');
	var placeholderWidth	 = sidebar.width();
	var placeholderHeight = sidebar.height();

	if (screenWidth >= 992) {
		placeholder.css({
			'position'	: 'relative',
			'margin-top': headerBar,
			'height'  : placeholderHeight
		});

		sidebar.css({
			'position': 'fixed',
			'width'	 : placeholderWidth,
			'height'  : placeholderHeight,
		});
	}

	/**
	 * This is replaced by data-attr at 'lib/init.php' in sidebar sections
	 */

	// sidebar.affix({
	// 	offset: {
	// 		top: headerBar,
	// 		bottom: 0,
	// 		bottom: function () {
	// 			return (this.bottom = $('.footer-primary').outerHeight(true))
	// 		}
	// 	}
	// });
};
