'use strict';

var bokaFlyttFormValidation = function(){
	$('#book_move').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			price_type: {
				validators: {
					// row: '.form-group',
					notEmpty: {
						message: 'Vänligen välj offertalternativ'
					}
				}
			}, 
			'customer_personal_number_0': {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			},
			move_date_1: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			move_date_2: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			move_time: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i starttid för flytt'
					},
					stringLength: {
						min: 4,
						message: 'Minst 4 tecken'
					}
				}
			},
			pack_date_1: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			pack_date_2: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			pack_time: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i starttid för packning'
					},
					stringLength: {
						min: 4,
						message: 'Minst 4 tecken'
					}
				}
			},
			moving_boxes_buy: {
				validators: {
					regexp: {
						regexp: /^[0-9]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			moving_boxes_rent: {
				validators: {
					regexp: {
						regexp: /^[0-9]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			delivery_of_moving_boxes_moving_material: {
				validators: {
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			collect_moving_boxes_moving_material: {
				validators: {
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			payment_type: {
				validators: {
					notEmpty: {
						message: 'Vänligen välj betalsätt'
					}
				}
			},
			payment_type_1: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					}
				}
			},
			payment_type_1_2_date: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			payment_type_1_2_time: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i tid'
					},
					stringLength: {
						min: 4,
						message: 'Minst 4 tecken'
					}
				}
			},
			payment_type_1_3_date: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			payment_type_1_3_time: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i tid'
					},
					stringLength: {
						min: 4,
						message: 'Minst 4 tecken'
					}
				}
			},
			invoice_personal_number: {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			},
			invoice_postal_address: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			invoice_postal_code: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postnummer'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			invoice_postal_city: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postort'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			read_accept_terms: {
				validators: {
					notEmpty: {
						message: 'Du måste bocka för att du godtar villkoren.'
					}
				}
			}
		}
	})
	.on('err.form.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
		$('#error-in-form-modal').modal('show');
	})
	.on('err.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
	})
	.on('status.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
	})
	.on('success.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
		/**
		 * Remove success indication from price-type
		 */
		var $parent;
		if (data.element.hasClass('price-type')) {
			$parent = data.element.parents('.form-group');
			$parent.find('.form-control-feedback').remove();
		}
		if (data.element.is(':radio') || data.element.is(':checkbox')) {
			$parent = data.element.parents('.form-group');
			$parent.removeClass('has-success');
			data.element.data('fv.icon').hide();
		}

		if (data.field === 'end_date_moving' && !data.fv.isValidField('end_date_moving')) {
			 // We need to revalidate the end date
			 data.fv.revalidateField('end_date_moving');
		}

		if (data.field === 'start_date_moving' && !data.fv.isValidField('start_date_moving')) {
			 // We need to revalidate the start date
			 data.fv.revalidateField('start_date_moving');

		}
	})
	.on('click', '.customer-personal-number-list-add-btn', function() {
			
		var cpnc 		= $('.customer-personal-number').length;
		var $template 	= $('#customer-personal-number-template');
		var $clone 		= $template
			.clone()
			.removeClass('hide')
			.removeAttr('id')
			.insertBefore($template);

		// Remove all fields for validation
		var i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {
			$('#book_move').formValidation('removeField', '['+$(this).prop('name')+']');
			i++;
		});

		// Add all fields for validation
		i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {

			$(this).prop('name', 'customer_personal_number_'+i);
			$('#book_move').formValidation('addField', 'customer_personal_number_'+i, {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			});
			i++;

		});

		$('#customer-personal-number-counter').val(cpnc);
	})
	.on('click', '.customer-personal-number-list-remove-btn', function() {
		var $row    = $(this).parents('.customer-personal-number-holder'),
		$option 	= $row.find('.customer-personal-number');
		
		// Remove element containing the option
		$row.remove();
		
		// Remove field
		$('#book_move').formValidation('removeField', $option);
	});


	var $parent = $('.price-type').parents('.form-group');
	$parent.find('.form-control-feedback').remove();


	// $(document).on('keyup', '#invoice-personal-number', function() {
	// 	$('#customer-personal-number-0').val($(this).val());
	// });
	$(document).on('keyup', '#customer-personal-number-0', function() {
		$('#invoice-personal-number').val($(this).val());
	});

};