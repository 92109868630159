'use strict';
// $ = jQuery;

function directValidation() {
	window.thisForm = $('#direct_form').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			direct_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i företagsnamn'
					}
				}
			},
			direct_email_address: {
				validators: {
					emailAddress: {
						message: 'Vänligen ange en riktig e-postadress'
					},
					callback: {
						message: 'Vänligen ange en e-postadress eller ett telefonnummer',
						callback: function(value, validator, $field) {
							var ret;
							if($(window.thisForm).find('[name="direct_phone"]').val().length) {
								ret = window.thisForm.data('formValidation').isValidField('direct_phone');
							} else {
								if(value.length > 0) {
									ret = true;
									window.thisForm.formValidation('updateStatus', 'direct_phone', 'VALID');
								} else {
									ret = false;
								}
							}
							return ret;
						}
					},
				}
			},
			direct_phone: {
				validators: {
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					callback: {
						message: 'Vänligen ange ett telefonnummer eller en e-postadress',
						callback: function(value, validator, $field) {
							var ret;
							if($(window.thisForm).find('[name="direct_email_address"]').val().length) {
								ret = window.thisForm.data('formValidation').isValidField('direct_email_address');
							} else {
								if(value.length > 0) {
									ret = true;
									window.thisForm.formValidation('updateStatus', 'direct_email_address', 'VALID');
								} else {
									ret = false;
								}
							}
							return ret;
						}
					}
				}
			}
		}
	});
}
