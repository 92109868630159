'use strict';
/**
 * Form Switch function
 */

var switchPicker = function() {
	var privateMoving 	= $('#private_moving');
	var privateItem		= $('.private-item');
	var companyMoving 	= $('#company_moving');
	var companyItem		= $('.company-item');
	var formPlaceholder	= $('.form-placeholder');
	var queryString		= window.location.search;

	/**
	 * First we check if the 'queryString' have a match with '?radio_moving=2' if so, we know which class we can display.
	 * In this case we will display 'companyItem' if its true. Also set 'privateItem' to false = display: none.
	 * Else if, it's false we set 'privateitem' to true and 'companyitem' to false instead and display 'privateItem' classes.
	 */

	if (queryString === '?radio_moving=2') {
		// privateMoving.prop('checked', false);
		// companyMoving.prop('checked', true);
	} else {
		// privateMoving.prop('checked', true);
		// companyMoving.prop('checked', false);
	}

	/**
	 * Append with the 'queryString' we need to do a check when everything is loaded to know which fields we're going with from start.
	 * So we check whitch of 'privateMoving' - 'companyMoving' is true and display it's class after that.
	 */

	if (privateMoving.is(':checked')) {
		privateItem.css('display', 'block');
		companyItem.css('display', 'none');
	}

	if (companyMoving.is(':checked')) {
		companyItem.css('display', 'block');
		privateItem.css('display', 'none');
	}

	/**
	 * And if the user change the statement we need to do it whit all the classes too.
	 * Check whitch of 'privateMoving' - 'companyMoving' is true and display it's class after that.
	 */

	privateMoving.on('click', function() {
		if (privateMoving.is(':checked')) {
			privateItem.css('display', 'block');
			companyItem.css('display', 'none');
			// console.log('private: checked');
		}
	});

	companyMoving.on('click', function() {
		if (companyMoving.is(':checked')) {
			companyItem.css('display', 'block');
			privateItem.css('display', 'none');
			// console.log('company: checked');
		}
	});
};

/**
 * Show surveying or not.
 */

var surveyingDate = function() {
	var surveyingNo  = $('#surveying_no');
	var surveyingYes = $('#surveying_yes');
	var container	  = $('#calender-container');

	surveyingNo.prop('checked', true);

	if (surveyingNo.is(':checked')) {
		container.css('display', 'none');
		surveyingYes.prop('checked', false);
	}

	surveyingNo.on('click', function() {
		if (surveyingNo.is(':checked')) {
			container.css('display', 'none');
			surveyingYes.prop('checked', false);
		}
	});

	surveyingYes.on('click', function() {
		if (surveyingYes.is(':checked')) {
			container.css('display', 'block');
			surveyingNo.prop('checked', false);
		}
	});
};

/*
 * Datepicker
 */

var datePicker = function() {
	var dateStart 	= $('.input-daterange');
	var start 		= $('#date_alt_two_start input');
	var end 		= $('#date_alt_two_end input');
	var alt_one		= $('#date_surveying_alt_one input');
	var alt_two		= $('#date_surveying_alt_two input');

	dateStart.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		daysOfWeekDisabled: '0,4,5,6',
		autoclose: true,
	});
	
	moment.locale('sv');
	start.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true,
		startDate: "+1d",
	})
	.on('changeDate', function(e) {
		$('#price_inquiry').formValidation('revalidateField', 'date_alt_one');
		end.datepicker('setStartDate', moment(e.date).add(0, 'day').format('YYYY-MM-DD'));

		if(moment(e.date).isSameOrAfter(moment(end.val()))) {
			end.val('');
			$('#price_inquiry').formValidation('revalidateField', 'date_alt_two');
		}
		end.prop('disabled', false);
	});

	end.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		$('#price_inquiry').formValidation('revalidateField', 'date_alt_two');
	});
	end.prop('disabled', true);

	alt_one.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		$('#price_inquiry').formValidation('revalidateField', 'surveying_start');
	});

	alt_two.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		$('#price_inquiry').formValidation('revalidateField', 'surveying_end');
	});



	/**
	 * global
	 */
	$('.date-picker').datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		
		var $thisForm = $(this).parents('form');
		$thisForm.formValidation('revalidateField', $(this).attr('id'));
	});
	
	$(document).on('change', 'input[name="fixed_price"]', function(e) {
		var $thisForm = $(this).parents('form');
		$thisForm.formValidation('revalidateField', 'email_attachment_choose');
	});

};

/**
 * Validation for price_inquiry_flytt
 */

var priceInquiryFlyttValidation = function() {
	var cpnlCount = 0;

	$('#price_inquiry').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			radio_rut: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			'customer_personal_number_0': {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			},
			rut_max_amount: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i belopp'
					},
					regexp: {
						regexp: /^[0-9\.\,]+$/,
						message: 'Endast siffror, komma och punkt är tillåtet'
					},
					stringLength: {
						min: 2,
						message: 'Minst 2 tecken'
					}
				}
			},
			radio_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			radio_moving_2: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			company_name_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i företagsnamn'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			street_address_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i gatuadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			cell_phone_nr: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i mobilnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			email_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i e-postadress'
					},
					emailAddress: {
						message: 'Detta är ingen validerad e-postadress'
					}
				}
			},
			quantity: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange antal leveranser'
					}
				}
			},
			answer_offert: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange hur du önskar svar på offerten'
					}
				}
			},
			full_name_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i för- och efternamn'
					},
					regexp: {
						regexp: /^[a-ö-A-ö ]+$/,
						message: 'Endast bokstäver och mellanslag är tillåtet'
					}
				}
			},
			mailling_address_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			phone_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			surveying_start: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Datumet måste vara mindre än senast datumet'
					}
				}
			},
			surveying_end: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Datumet måste vara mer än tidigast datumet'
               }
				}
			},
			street_address_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange gatuadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			mailling_address_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: {
							message: 'Vänligen ange postadress'
						}
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			type_of_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj typ av flytt'
					}
				}
			},
			meters_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange avstånd.'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			floor_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange antal trappor'
					}
				}
			},
			elevator_away: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange typ av hiss'
					}
				}
			},
			street_address_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange gatuadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			mailling_address_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: {
							message: 'Vänligen ange postadress'
						}
					}
				}
			},
			type_of_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj typ av flytt'
					}
				}
			},
			meters_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: {
							message: 'Vänligen ange avstånd'
						}
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			floor_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange antal trappor'
					}
				}
			},
			elevator_to: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange typ av hiss'
					}
				}
			},
			many_rooms_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Ange antal rum (Rum & Kök)'
					}
				}
			},
			living_area_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Ange total boyta m2'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			many_people_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Ange antal personer i hushållet'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			total_area_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Ange total biyta'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			find_us_other: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange hur ni kom i kontakta med oss'
					}
				}
			},
			recommend_off_other: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			working_as: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Fyll i ert yrke'
					}
				}
			},
			org_nr_moving: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll ert organisationsnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						max: 25,
						min: 5,
						message: 'Får endast vara 5 - 25 siffror'
					}
				}
			},
			workingspace_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll antalet arbetsplatser'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						min: 1,
						message: 'Minst 1'
					}
				}
			},
			area_range: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll totalyta m2'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						min: 1,
						message: 'Minst 1'
					}
				}
			},
			date_alt_one: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			date_alt_two: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i önskat datum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange YYYY-MM-DD'
					}
				}
			},
			radio_cleaning: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			radio_pet: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			cleaning_quotation_condition: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			privacy_policy: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Du måste godkänna vår integritetspolicy för att skicka offertförfrågan.'
					}
				}
			},
			// 'email_attachment_choose': {
			// 	validators: {
			// 		callback: {
			// 			message: 'För att kunna ange ett fast pris behöver vi bilder på hela objektet.',
			// 			callback: function(value, validator, $field) {
			// 				if($('input[name="fixed_price"]:checked').val().toLowerCase() == 'ja' && $('input[name="email_attachments[]"]').length == 0) {
			// 					return false;
			// 				}
			// 				return true;
			// 			}
			// 		}
			// 	}
			// }
		}
	})
	.on('success.field.fv', function(e, data) {
		if (data.field === 'end_date_moving' && !data.fv.isValidField('end_date_moving')) {
			 // We need to revalidate the end date
			 data.fv.revalidateField('end_date_moving');
		}

		if (data.field === 'start_date_moving' && !data.fv.isValidField('start_date_moving')) {
			 // We need to revalidate the start date
			 data.fv.revalidateField('start_date_moving');
		}


		/**
		 * Remove success indication from radiobuttons
		 */
		if (data.element.is(':radio')) {
			var $parent = data.element.parents('.form-group');
			$parent.removeClass('has-success');
			data.element.data('fv.icon').hide();
			$parent.find('.form-control-feedback').hide();
		}

		$('#submit_btn').removeClass('disabled').removeAttr('disabled');


	})
	.on('err.field.fv', function(e, data) {
		/**
		 * Remove error indication from radiobuttons
		 */
		if (data.element.is(':radio')) {
			var $parent = data.element.parents('.form-group');

			var formControlFeedback = $parent.find('.form-control-feedback').clone();
			$parent.find('.form-control-feedback').remove();
			$parent.append(formControlFeedback);
			// data.element.data('fv.icon').hide();
		}

		$('#submit_btn').removeClass('disabled').removeAttr('disabled');

	})
	.on('err.form.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
		$('#error-in-form-modal').modal('show');
	})
	.on('status.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
	})
	.on('click', '.customer-personal-number-list-add-btn', function() {
			
		var cpnc 		= $('.customer-personal-number').length;
		var $template 	= $('#customer-personal-number-template');
		var $clone 		= $template
			.clone()
			.removeClass('hide')
			.removeAttr('id')
			.insertBefore($template);

		// Remove all fields for validation
		var i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {
			$('#book_move').formValidation('removeField', '['+$(this).prop('name')+']');
			i++;
		});

		// Add all fields for validation
		i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {

			$(this).prop('name', 'customer_personal_number_'+i);
			$('#book_move').formValidation('addField', 'customer_personal_number_'+i, {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			});
			i++;

		});

		$('#customer-personal-number-counter').val(cpnc);
	})
	.on('click', '.customer-personal-number-list-remove-btn', function() {
		var $row    = $(this).parents('.customer-personal-number-holder'),
		$option 	= $row.find('.customer-personal-number');
		
		// Remove element containing the option
		$row.remove();
		
		// Remove field
		$('#book_move').formValidation('removeField', $option);
		
		var cpnc 		= ($('.customer-personal-number').length-1);
		$('#customer-personal-number-counter').val(cpnc);
	})
	.on('change', 'select[name="recommend_off_other"]', function(e) {
		var thisValue = $('option:selected',e.target).val();

		// $('select[name="recommend_off_other"] option').each(function(i, item) { console.log($(item).val().toLowerCase()); });
		if(
			thisValue.toLowerCase() === 'bomäklarna' ||
			thisValue.toLowerCase() === 'fastighetsbyrån' ||
			thisValue.toLowerCase() === 'erik olsson' ||
			thisValue.toLowerCase() === 'länsförsäkringar' ||
			thisValue.toLowerCase() === 'shurgard' ||
			thisValue.toLowerCase() === 'mäklarringen' ||
			thisValue.toLowerCase() === 'mäklarhuset' ||
			thisValue.toLowerCase() === 'widerlöv' ||
			thisValue.toLowerCase() === 'svensk fastighetsförmedling' ||
			thisValue.toLowerCase() === 'personlig rekommendation' ||
			thisValue.toLowerCase() === 'annan rekommendation' ||
			thisValue.toLowerCase() === 'annat'
		) {
			/**
			 * Visa vem fält
			 */
			$('.recommend_off_other_who_wrapper').show();
		} else {
			/**
			 * Dölj vem fält
			 */
			$('.recommend_off_other_who_wrapper').hide();

		}
		
	});
	
};


function uploadFiles(event){
	
	$('#email_attachment_info').html('<i class="fa fa-spinner fa-pulse"></i> Laddar upp, vänligen vänta...');

	// var files = event.target.files;
	// console.log($("#email_attachment_choose").get(0).files);
	// return false;
	var files = $("#email_attachment_choose").get(0).files;
	var $thisForm = $(this).parents('form');
	$.each(files, function(key, value){
		var data = new FormData();
		data.append(key, value);
		$.ajax({
			url: '/wp-content/themes/budcenter/templates/forms-price_inquiry-flytt/ajax-upload-file.php?files',
			type: 'POST',
			data: data,
			cache: false,
			dataType: 'json',
			processData: false,
			contentType: false,
			success: function(data, textStatus, jqXHR){
				if(typeof data.error === 'undefined'){
					$('#email_attachment_info').html('Din bild är nu uppladdad..');
					$('#email_attachment_choose').val('');

					var fileNum = data.id;

					var field = ''; 
					field += '<div id="file-'+fileNum+'" class="form-group">';
					field += '	'+data.files[0]+' <button type="button" class="btn btn-sm btn-danger remove-attachment" data-id="'+fileNum+'">X</button>';
					field += '	<input type="hidden" name="email_attachments[]" id="email_attachments'+fileNum+'" class="email_attachments" value="'+data.files[0]+'" />';
					field += '</div>';
					$('#files').append(field);

					$('#files').removeClass('display-none');
					
					$thisForm.formValidation('revalidateField', 'email_attachment_choose');

					// $('#email_attachments').val(data.files[0]);
				}
				else{
					$('#email_attachment_info').html('FEL: '+data.error);
				}
			},
			error: function(jqXHR, textStatus, errorThrown)
			{
				$('#email_attachment_info').html('FEL: '+textStatus);
			}
		});
	});

}
var ajaxUploadAttachment = function(){
	$(document).on('change', '#email_attachment_choose',uploadFiles);
	$(document).on('click', '.remove-attachment', function(e) {
		$('#file-'+$(e.target).attr('data-id')).remove();
	});


};
	