'use strict';
// $ = jQuery;

/**
 * Datepicker
 */

var tranportDate = function() {
	var orderDate = $('#transport_order_date input');

	orderDate.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		$('#book_form').formValidation('revalidateField', 'order_date');
	});
};

/**
 * Validation 'Boka bud och transport'
 */

var bookValidation = function() {
	$('#book_form').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			order_company: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i företagsnamn'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			order_reference: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i referens'
					}
				}
			},
			order_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
               },
					stringLength: {
						max: 15,
						min: 5,
						message: 'Ange minst 5 och maximalt 15 siffror'
					}
				}
			},
			order_email: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i e-postadress'
					},
					emailAddress: {
						message: 'Detta är ingen validerad e-postadress'
					}
				}
			},
			order_date: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i leveransdatum'
					},
					date: {
						format: 'YYYY-MM-DD',
						message: 'Fel format, vänligen ange ÅÅÅÅ-MM-DD'
					}
				}
			},
			order_from_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i för- och efternamn'
					},
					regexp: {
						regexp: /^[a-öA-Ö ]+$/,
						message: 'Endast bokstäver och mellanslag är tillåtet'
					},
				}
			},
			order_from_reference: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i referens'
					}
				}
			},
			order_from_address: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i adress'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			order_from_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						max: 15,
						min: 5,
						message: 'Ange minst 5 och maximalt 15 siffror'
					}
				}
			},
			order_from_leave: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i klockslag'
					},
					regexp: {
						regexp: /^[0-9\-\:\ ]+$/,
						message: 'Endast siffror, mellanslag, bindestreck och kolon är tillåtet'
					},
					stringLength: {
						min: 4,
						max: 13,
						message: 'Ange minst 4 och maximalt 13 siffror'
					}
				}
			},
			order_to_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i för- och efternamn'
					}
				}
			},
			order_to_reference: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i referens'
					}
				}
			},
			order_to_address: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i adress'
					}
				}
			},
			order_to_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						max: 15,
						min: 5,
						message: 'Ange minst 5 och maximalt 15 siffror'
					}
				}
			},
			order_to_leave: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i klockslag'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						min: 4,
						max: 13,
						message: 'Ange minst 4 och maximalt 13 siffror'
					}
				},
			},
			transport_vehicle: {
				validators: {
					 notEmpty: {
						  message: 'Vänligen välj fordonstyp'
					 }
				}
			},
			order_from_accept_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						max: 15,
						min: 5,
						message: 'Ange minst 5 och maximalt 15 siffror'
					}
				}
			},
			privacy_policy: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Du måste godkänna vår integritetspolicy för att skicka offertförfrågan.'
					}
				}
			}
		}
	});
};
