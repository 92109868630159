'use strict';
// $ = jQuery;

/**
 * Information helper block in click
 */

var ifChoosen = function() {
	var trigger 	= $('.btn-if');

	trigger.on('click', function() {
		var elementsShow 	= $(this).data('choose-if-show');
		var elementsHide 	= $(this).data('choose-if-hide');
		var ifElementsKey 	= $(this).data('if-element-key');
		var ifElementsValue	= $(this).data('if-element-value');
		var elementShowArr;
		var elementHideArr;
		var ifElements;

		if (elementsShow) {

			/**
			 * Kontrollera om vi ska kolla mot ett annat fälts värde.
			 */
			ifElements = true;
			if(ifElementsKey && ifElementsKey.length > 0){
				ifElements = false;	
				if($(ifElementsKey).val() === ifElementsValue){
					ifElements = true;
				}
			}
			if(ifElements === true){

				if (elementsShow.indexOf(',') >= -1) {
					elementShowArr = elementsShow.split(',');

					$.each(elementShowArr, function(index,item) {
						$(item).show();
					});
				} else {
					$(elementsShow).show();
				}
			}
		}

		if (elementsHide) {
			if (elementsHide.indexOf(',') > -1) {
				elementHideArr = elementsHide.split(',');

				$.each(elementHideArr, function(index,item) {
					$(item).hide();
				});
			} else {
				$(elementsHide).hide();
			}
		}
	});

	$('.btn-if:checked').each(function(){
		$(this).trigger('click');
	});
};

function helperBlock() {
	var trigger = $('.poper');

	trigger.on('click', function() {
		$(this).popover('show');
	});
}

/**
 * Alert message when user click 'send'.
 */

var showMessageAlert = function() {
	$('#send-form-modal').modal('show');
};

/**
 * Trigger slideout
 */
var slideOut = function() {
	$('#slideout').on('mouseenter', function(e) {
		if(!$('#slideout').hasClass('in') && $('#slideout').css('right') !== '0px') {
			$('#slideout').addClass('in');
		}
	});
	$('#slideout .toggle-btn').on('click', function(e) {
		console.log($('#slideout').css('right'));
		if($('#slideout').hasClass('in')) {
			$('#slideout').addClass('in-processing');
			$('#slideout').removeClass('in');

			setTimeout(function() {
				$('#slideout').removeClass('in-processing');
			}, 1000);
		}
	});
	$('#slideout .toggle-btn').on('click', function(e) {
		if(!$('#slideout').hasClass('in') && !$('#slideout').hasClass('in-processing')) {
			$('#slideout').addClass('in');
		}
	});
	// $('#slideout').on('click', function(e) {
	// 	console.log($(e.target));
	// 	if($(e.target).hasClass('toggle-btn')) {
	// 		console.log('foo');
	// 	} else {
	// 		console.log('bar');
	// 		if(!$('#slideout').hasClass('in')) {
	// 			console.log('baz');
	// 			$('#slideout').addClass('in');
	// 		}
	// 	}
	// });
	
	// $('#slideout .toggle-btn').on('click', function() {
	// 	$('#slideout').toggleClass('in');
	// });

};


/**
 * If 'scroll-pos' is greater then 'header-bar'
 * We set 'header__top-navigation' to fixed and add
 * margin-top of the height from 'header-bar' to header.
 * That gives us a smooth scrolling and no jumpings.
 */

 function headerScroll() {
   var menu 			= $('.header__top-navigation');
   var menuHeight    = menu.outerHeight();
   var menuUl        = $('#menu-main-navigation');

   var header 			= $('.header');
   var headerBar 		= $('.header-top-bar').outerHeight();
   var phoneBar      = $('.phone-bar');

   var screenWidth	= $(window).width();


	$(window).on('scroll', function() {
      var scrollPos		= $(window).scrollTop();

      if (scrollPos >= headerBar) {
         menu.addClass('is-fixed');
         phoneBar.addClass('phone-bar__sticky');
         menuUl.addClass('phone-bar--visable');
         header.css('margin-top', menuHeight);
      } else {
         menu.removeClass('is-fixed');
         phoneBar.removeClass('phone-bar__sticky');
         menuUl.removeClass('phone-bar--visable');
         header.css('margin-top', 0);
      }
	});
}

/*
 * On click, toggle navigation-sidebar
 */

function navgationToggle() {
   var toggleButton = $('#menu-toggle');
   var close        = $('#close-sidebar-nav');
   var logo         = $('.header__logo');

   toggleButton.on('click', function() {
      // e.preventDefault();
      $("#wrapper").toggleClass("toggled");
   });

   close.on('click', function() {
      $('#wrapper').removeClass('toggled');
   });


}

/**
 * Make sure that our header have the same height
 */

function itemHeight() {
	var highestItem = 0;
	var $workflowItems;
	if($('.item__height').length > 0){

		$workflowItems = $('.item__height');

		$.each($workflowItems, function() {
			var height = $(this).outerHeight();

			if (height > highestItem) {
				highestItem = height;
			}
		});

		$.each($workflowItems, function() {
			$(this).css('min-height', highestItem + 'px');
		});
	}
}

var copyInputField = function() {
	$(document).on('click', '.btn-copy-input-field', function(){

		var fromField 	= $(this).data('copy-input-field-from');
		var toField 	= $(this).data('copy-input-field-to');

		var toFieldArr 	= toField.split(',');

		if (fromField) {
			if (fromField.indexOf(',') >= -1) {
				var fromFieldArr = fromField.split(',');

				$.each(fromFieldArr, function(index,item) {
					var fromValue 	= $(item).val();
					toField 		= toFieldArr[index];
					$(toField).val(fromValue);
					var parentFormId = $(toField).parents('form').attr('id');
					$('#' + parentFormId).formValidation('revalidateField', toField.replace('#',''));
				});
			} else {
				var fromValue 	= $(fromField).val();
				toField 		= $(toField);
				$(toField).val(fromValue);
				var parentFormId = $(toField).parents('form').attr('id');
				$('#' + parentFormId).formValidation('revalidateField', toField.replace('#',''));
			}
		}
	});
};

var toggleElement = function() {

	$(document).on('click','.toggle-element-btn',function(){
		console.log('test');
		var toggleElement 	= $(this).data('toggle-element');
		var closeElement 	= $(this).data('close-element');
		
		/**
		 * if the amount of close elements that is visible and not the same as the 
		 * element to be toggled is more than 0, then we should close the elements.
		 * when the elements is closed vi toggle the element to toggle.
		 */
		if($(closeElement+':visible').not(toggleElement).length > 0) {
		
			$(closeElement+':visible')
				.not($(this).data('toggle-element'))
				.fadeToggle(400,function(){
					$(toggleElement).fadeToggle();
				});
		}else{
			$(toggleElement).fadeToggle();
		}
	});
};

var jqueryNiteaEqualHeight = function() {
	if($('[data-n-equal-height-rel]').length) {

		$().nEqualHeight({
			type: 'max',
			outerHeight: true,
			resize: true,
			ignoreMin: true,
			ignoreMax: true
		});
	}
};
