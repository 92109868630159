/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
$ = jQuery;
(function($) {

   // Use this variable to set up the common and page specific functions. If you
   // rename this variable, you will also need to rename the namespace below.
   var Sage = {
      // All pages
      'common': {
         init: function() {
            // Header goes fixed
            headerScroll();
            // Toggle navigation-sidebar
            navgationToggle();
            // Set navigation-sidebar to fixed
            sidebarFixed();
            
            // SlideOut right side bar
            slideOut();
            // Direct contact Validation
            directValidation();
            callMeSendMessage();
            // Show message after sending mail.
            showMessageAlert();
            // Copy a input field value to another.
            copyInputField();
            
            ifChoosen();
            jqueryNiteaEqualHeight();

            bootstrapSelectTabOnLoad();
         },
         finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
         }
      },
      // Home page
      'home': {
         init: function() {
            itemHeight();
            quotesSlider();
         },
         finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
         }
      },
      'page_template_template_bud_transport': {
         init: function() {
            // Form validation
            bookValidation();
            // Helper block
            helperBlock();
            tranportDate();
         }
      },
      'lamna_omdome': {
         init: function() {
            // Validation
            opinionValidation();
         }
      },
      'kontakt': {
         init: function() {
            // google maps
            googleMaps();
            // Validation
            contactValidation();
         }
      },
      'page_template_template_signup': {
         init: function() {
            // Validation
            signUpValidation();
         }
      },
      'page_template_template_price_inquiry_flytt': {
         init: function() {
            priceInquiryFlyttValidation();
            switchPicker();
            surveyingDate();
            datePicker();
            // Helper block
            helperBlock();
         },
         finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            ajaxUploadAttachment();
         }
      },
      'page_template_template_price_inquiry_bud': {
         init: function() {
            priceInquiryFlyttValidation();
            // Helper block
            helperBlock();
         }
      },
      'page_template_template_boka_offert': {
         init: function() {
            datePicker();
            // Helper block
            helperBlock();

            bokaFlyttFormValidation();
            // ifChoosen();
            toggleElement();
         }
      },
      'page_template_template_prisforfragan_flyttstadning': {
         init: function() {

            // Helper block
            helperBlock();

            formFlyttstadningPrisforfragan();
            // ifChoosen();
            toggleElement();

         }
      },
      'bli_kund': {
         init: function() {
            signUpValidation();
         }
      }
   };

   // The routing fires all common scripts, followed by the page specific scripts.
   // Add additional events for more control over timing e.g. a finalize event
   var UTIL = {
      fire: function(func, funcname, args) {
         var fire;
         var namespace = Sage;
         funcname = (funcname === undefined) ? 'init' : funcname;
         fire = func !== '';
         fire = fire && namespace[func];
         fire = fire && typeof namespace[func][funcname] === 'function';

         if (fire) {
            namespace[func][funcname](args);
         }
      },
      loadEvents: function() {
         // Fire common init JS
         UTIL.fire('common');

         // Fire page-specific init JS, and then finalize JS
         $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
         });

         // Fire common finalize JS
         UTIL.fire('common', 'finalize');
      }
   };

   // Load Events
   $(document).ready(UTIL.loadEvents);

   })(jQuery); // Fully reference jQuery after this point.
