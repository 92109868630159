'use strict';
// $ = jQuery;

function contactValidation() {
	$('#contact_form').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			contact_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i för- och efternamn'
					}
				}
			},
			contact_email: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i e-postadress'
					},
					emailAddress: {
						message: 'Detta är ingen validerad e-postadress'
					}
				}
			},
			contact_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror är tillåtet'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			contact_message: {
				validators: {
					notEmpty: {
						message: 'Vänligen lämna ett meddelande'
					}
				}
			}
		}
	});
}
