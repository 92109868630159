'use strict';

// $ = jQuery;

/*
 * Main Slider on the front pager
 * That lists customer-quotes.
 */

/**
 * Check highest hight of all slides and set that height.
 * Every slides get the same height then of the heights one.
 */

var sliderHeight = function() {
	var list = $('.customer-quotes__area');
	var item = $('.customer-quotes__area__item');

	item.each(function() {
		var itemHeight = item.map(function() {
			return $(this).height();
		}).get();

		/**
		 * Math.max takes a variable number of arguments
		 * 'apply' is equivalent to passing each height as an argument
		 */

		var maxHeight = Math.max.apply(null, itemHeight);

		// Set each height to the max height
		list.height(maxHeight);
		item.height(maxHeight);
	});
};
function quotesSlider() {
	$('#customer_quotes').lightSlider({
		item: 1,
		loop: true,
		auto: true,
		adaptiveHeight: false,
		slideMove: 1,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		speed: 600,
		pause: 9000,
		slideMargin: 30,
		responsive : [
			{
				breakpoint: 992,
				settings: {
					item: 1,
					slideMove: 1,
					slideMargin: 6,
				}
			},
			{
			breakpoint: 480,
				settings: {
					item: 1,
					slideMove: 1
				}
			}
		],

		onSliderLoad: function() {
			sliderHeight();
		}
	});
}

