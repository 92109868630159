'use strict';
// $ = jQuery;

function opinionValidation() {
	$('#opinion_form').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			stuff_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen ange ditt namn'
					}
				}
			},
			rating_one: {
				validators: {
					notEmpty: {
						message: 'Vänligen ange hur du upplevde offerten'
					}
				}
			},
			rating_two: {
				validators: {
					notEmpty: {
						message: 'Vänligen ange hur du upplevde vår kundservice'
					}
				}
			},
			stuff_opinion: {
				validators: {
					notEmpty: {
						message: 'Vänligen skriv ditt omdöme'
					}
				}
			},
			budcenter_opinion: {
				validators: {
					notEmpty: {
						message: 'Vänligen skriv ditt omdöme'
					}
				}
			}
		}
	});
}
