var bootstrapSelectTabOnLoad = function () {

	// Javascript to enable link to tab
	var url = document.location.toString();
	if ($(window.location.hash).length) {
	    $('a[href="'+window.location.hash+'"]').tab('show');
	    window.scrollTo(0,0);
	    $("html, body").animate({ scrollTop: 0 }, "slow");
  		return false;
	} 

	// // With HTML5 history API, we can easily prevent scrolling!
	// $('.nav-tabs a').on('shown.bs.tab', function (e) {
	//     if(history.pushState) {
	//         history.pushState(null, null, e.target.hash); 
	//     } else {
	//         window.location.hash = e.target.hash; //Polyfill for old browsers
	//     }
	// })
};