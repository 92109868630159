'use strict';

/**
 * Validation for price_inquiry_flytt
 */

var formFlyttstadningPrisforfragan = function() {

	// $('.datepicker').datepicker({
	// 	language: 'sv',
	// 	format: 'yyyy-mm-dd',
	// 	autoclose: true
	// })
	// .on('changeDate', function(e) {
	// 	$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', $(this).attr('name'));
	// });

	var start 		= $('#stay-cleaning-date-earliest');
	var end 		= $('#stay-cleaning-date-latest');
	
	moment.locale('sv');
	start.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true,
		startDate: "+1d",
	})
	.on('changeDate', function(e) {
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', $(this).attr('name'));

		end.datepicker('setStartDate', moment(e.date).add(0, 'day').format('YYYY-MM-DD'));

		if(moment(e.date).isSameOrAfter(moment(end.val()))) {
			end.val('');
			$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', end.attr('name'));

		}
		end.prop('disabled', false);
	});

	end.datepicker({
		language: 'sv',
		format: 'yyyy-mm-dd',
		autoclose: true
	})
	.on('changeDate', function(e) {
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', $(this).attr('name'));
	});
	end.prop('disabled', true);




	$(document).on('change', 'input[name=customer_type]', function() {
		
		$('input[name=facilty_type]').prop('checked', false);
		$('input[name=radio_rut]').prop('checked', false);
		$('input[name=radio_pet]').prop('checked', false);
		$('select[name=housing_type] option:selected').prop('selected', false);
		
		/**
		 * revalidate
		 */
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', 'facilty_type');
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', 'radio_rut');
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', 'housing_type');
		$('#'+$(this).parents('form').attr('id')).formValidation('revalidateField', 'radio_pet');
	});




	$('#flyttstadning-offertforfragan').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon1asd glyphicon-ok2',
			 invalid: 'glyphiconasd glyphicon-remove2',
			 validating: 'glyphiconasd glyphicon-refresh2'
		},
		fields: {
			customer_type: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			facilty_type: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			radio_rut: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			'customer_personal_number_0': {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			},
			housing_type: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			facilty_living_area: {
				row: '.col-sm-6',
				validators: {
					notEmpty: {
						message: 'Vänligen ange boyta'
					},
					regexp: {
						regexp: /^\d+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			facilty_secondary_area: {
				row: '.col-sm-6',
				validators: {
					notEmpty: {
						message: 'Vänligen ange biyta'
					},
					regexp: {
						regexp: /^\d+$/,
						message: 'Endast siffror är tillåtet'
					}
				}
			},
			radio_facilty_access: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			radio_pet: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}
				}
			},
			company_name: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i ert företagsnamn'
					},
					regexp: {
						regexp: /^[a-öA-ö0-9 ]+$/,
						message: 'Endast bokstäver, siffror och mellanslag är tillåtet'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			company_organisation_number: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i ert organisationsnummer'
					},
					regexp: {
						regexp: /^[0-9\-]+$/,
						message: 'Endast siffror och - är tillåtet'
					},
					stringLength: {
						min: 10,
						message: 'Minst 10 tecken'
					}
				}
			},
			fullname: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i för- och efternamn'
					},
					regexp: {
						regexp: /^[a-ö-A-ö ]+$/,
						message: 'Endast bokstäver och mellanslag är tillåtet'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			phone: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i mobilnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			email_address: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i e-postadress'
					},
					emailAddress: {
						message: 'Vänligen ange en riktigt e-postadress'
					}
				}
			},
			postal_address: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postadress'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			postal_city: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postort'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			postal_code: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen ange postnummer.'
					},
					regexp: {
						regexp: /^[0-9]{5}$/,
						message: 'Endast siffror är tillåtet. Ange 5 st'
					}
				}
			},
			stay_cleaning_date_earliest: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett datum'
					},
					regexp: {
						regexp: /^(2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
						message: 'Endast siffror och bindestreck är tillåtet'
					}

				}
			},
			stay_cleaning_date_latest: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett datum'
					},
					regexp: {
						regexp: /^(2[0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
						message: 'Endast siffror och bindestreck är tillåtet'
					}

				}
			},
			recommended_by: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Vänligen välj ett alternativ'
					}

				}
			},
			privacy_policy: {
				row: '.form-group',
				validators: {
					notEmpty: {
						message: 'Du måste godkänna vår integritetspolicy för att skicka offertförfrågan.'
					}
				}
			}
		}
	})
	.on('success.field.fv', function(e, data) {
		if (data.field === 'end_date_moving' && !data.fv.isValidField('end_date_moving')) {
			 // We need to revalidate the end date
			 data.fv.revalidateField('end_date_moving');
		}

		if (data.field === 'start_date_moving' && !data.fv.isValidField('start_date_moving')) {
			 // We need to revalidate the start date
			 data.fv.revalidateField('start_date_moving');
		}


		/**
		 * Remove success indication from radiobuttons
		 */
		if (data.element.is(':radio')) {
			var $parent = data.element.parents('.form-group');
			$parent.removeClass('has-success');
			data.element.data('fv.icon').hide();
		}

		$('#submit_btn').removeClass('disabled').removeAttr('disabled');

	})

	.on('err.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
	})
	.on('err.form.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
		$('#error-in-form-modal').modal('show');
	})
	.on('status.field.fv', function(e, data) {
		$('#submit_btn').removeClass('disabled').removeAttr('disabled');
	})
	.on('click', '.customer-personal-number-list-add-btn', function() {
			
		var cpnc 		= $('.customer-personal-number').length;
		var $template 	= $('#customer-personal-number-template');
		var $clone 		= $template
			.clone()
			.removeClass('hide')
			.removeAttr('id')
			.insertBefore($template);

		// Remove all fields for validation
		var i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {
			$('#book_move').formValidation('removeField', '['+$(this).prop('name')+']');
			i++;
		});

		// Add all fields for validation
		i = 0;
		$('.customer-personal-number').not('#customer-personal-number-template').each(function() {

			$(this).prop('name', 'customer_personal_number_'+i);
			$('#book_move').formValidation('addField', 'customer_personal_number_'+i, {
				validators: {
					row: '.form-group',
					notEmpty: {
						message: 'Vänligen fyll i personnummer'
					},
					regexp: {
						regexp: /^([0-9]{6})?([-]?)?([0-9]{4})+$/,
						message: 'Ange fullständigt personnummer YYMMDD-XXXX'
					},
					stringLength: {
						min: 10,
						message: 'Ange minst 10 tecken'
					}
				}
			});
			i++;

		});

		$('#customer-personal-number-counter').val(cpnc);
	})
	.on('click', '.customer-personal-number-list-remove-btn', function() {
		var $row    = $(this).parents('.customer-personal-number-holder'),
		$option 	= $row.find('.customer-personal-number');
		
		// Remove element containing the option
		$row.remove();
		
		// Remove field
		$('#book_move').formValidation('removeField', $option);
		
		var cpnc 		= ($('.customer-personal-number').length-1);
		$('#customer-personal-number-counter').val(cpnc);
	})
	.on('change', 'select[name="recommended_by"]', function(e) {
		var thisValue = $('option:selected',e.target).val();

		// $('select[name="recommended_by"] option').each(function(i, item) { console.log($(item).val().toLowerCase()); });
		if(
			thisValue.toLowerCase() === 'bomäklarna' ||
			thisValue.toLowerCase() === 'fastighetsbyrån' ||
			thisValue.toLowerCase() === 'erik olsson' ||
			thisValue.toLowerCase() === 'länsförsäkringar' ||
			thisValue.toLowerCase() === 'shurgard' ||
			thisValue.toLowerCase() === 'mäklarringen' ||
			thisValue.toLowerCase() === 'mäklarhuset' ||
			thisValue.toLowerCase() === 'widerlöv' ||
			thisValue.toLowerCase() === 'svensk fastighetsförmedling' ||
			thisValue.toLowerCase() === 'personlig rekommendation' ||
			thisValue.toLowerCase() === 'annan rekommendation' ||
			thisValue.toLowerCase() === 'annat'
		) {
			/**
			 * Visa vem fält
			 */
			$('.recommended_by_who_wrapper').show();
		} else {
			/**
			 * Dölj vem fält
			 */
			$('.recommended_by_who_wrapper').hide();

		}
		
	});
	
};