'use strict';
// $ = jQuery;

function signUpValidation() {
	$('#signup_form').formValidation({
		framework: 'bootstrap',
		icon: {
			 valid: 'glyphicon glyphicon-ok',
			 invalid: 'glyphicon glyphicon-remove',
			 validating: 'glyphicon glyphicon-refresh'
		},
		fields: {
			signup_company: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i företagsnamn'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			signup_org_number: {
				validators: {
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					},
					notEmpty: {
						message: 'Vänligen fyll i ert organisationsnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
               }
				}
			},
			signup_name: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i fullständigt namn'
					},
					regexp: {
						regexp: /^[a-ö-A-ö ]+$/,
						message: 'Endast bokstäver och mellanslag är tillåtet'
					}
				}
			},
			signup_working_as: {
				validators: {
					validators: {
						notEmpty: {
							message: 'Vänligen fyll i vad du arbetar med'
						}
					}
				}
			},
			signup_address: {
				validators: {
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					},
					notEmpty: {
						message: 'Vänligen fyll i en adress'
					}
				}
			},
			signup_zip_code: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i postnummer (123 45)'
					},
					zipCode: {
						country: 'SE',
						message: 'Detta är inte ett validerat postnummer'
					}
				}
			},
			signup_city: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i ort'
					},
					regexp: {
						regexp: /^[a-ö-A-ö ]+$/,
						message: 'Endast bokstäver är tillåtet'
					},
					stringLength: {
						min: 3,
						message: 'Minst 3 tecken'
					}
				}
			},
			signup_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i telefonnummer'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					}
				}
			},
			signup_cell_phone: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i mobilnummer'
					},
					stringLength: {
						min: 5,
						message: 'Minst 5 tecken'
					},
					regexp: {
					regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					}
				}
			},
			signup_email: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i e-postadress'
					},
					emailAddress: {
						message: 'Detta är ingen validerad e-postadress'
					}
				}
			},
			signup_max_credit: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i kreditbelopp'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					}
				}
			},
			signup_monthly_bid: {
				validators: {
					notEmpty: {
						message: 'Vänligen fyll i bud per månad'
					},
					regexp: {
						regexp: /^[0-9\-\ ]+$/,
						message: 'Endast siffror, mellanslag och bindestreck är tillåtet'
					}
				}
			},
			signup_send_as: {
				validators: {
					notEmpty: {
						message: 'Välj utskickningsätt'
					}
				}
			}
		}
	});
}
